import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className='bottombar'>
        {/* This is footer */}
      </div>
    </footer>
  );
};

export default Footer;