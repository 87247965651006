import React from 'react';
import './TopBar.css';


const TopBar: React.FC = () => {
  return (
    <div className="topbar">
      {/* This is top bar */}
    </div>
  );
}

export default TopBar;