import React from 'react';
import logo from '../assets/images/Deltodon_Logo.png';
import './Header.css';
import TopBar from './TopBar'

const Header: React.FC = () => {
  return (
    <header className="navbar">
      <TopBar />
      <div id="header-left">
        <div id="logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div id="site-name">
          {/* <a href="index.html" title="Home">Deltodon</a> */}
          Deltodon
        </div>
        <div id="site-slogan">
          Vision and Knowledge                    
        </div>
      </div>
    </header>
  );
};

export default Header;