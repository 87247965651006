import React from 'react';
import './MainContent.css';

const MainContent: React.FC = () => {
  return (
    <div className="main-body">
      This page is currently under development.
    </div>
  );
}

export default MainContent;